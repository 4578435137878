<template>
  <Delete
    action-handler="lost-founds/delete"
    :payload-value="{ id: record.id }"
    :title="$t('title')"
    :description="$t('desc', { name: record.name })"
    :success-title="$t('deleted-title')"
    :success-description="$t('deleted-desc', { name: record.name })"
    @close="$emit('delete')"
  >
    <template #handler="{ show }">
      <slot name="handler" :show="show" />
    </template>
  </Delete>
</template>

<i18n>
{
  "en": {
    "title": "Delete Lost & Found?",
    "desc": "Are you use you want to delete Lost & Found <strong>{name}</strong> ?",
    "deleted-title": "Lost & Found deleted",
    "deleted-desc": "Lost & Found <strong>{name}</strong> has been removed."
  },
  "ja": {
    "title": "忘れ物を削除?",
    "desc": "本当に <strong>{name}</strong> を削除しますか？",
    "deleted-title": "忘れ物削除完了",
    "deleted-desc": "忘れ物 <strong>{name}</strong> が削除されました。"
  }
}
</i18n>

<script>
import Delete from '@/components/PopupDelete';

export default {
  name: 'DeleteLostFounds',
  components: {
    Delete,
  },
  props: {
    record: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['delete'],
};
</script>
