export const STATUS = [
  { label: 'Not Found', value: 'not found' },
  { label: 'Not Collected', value: 'not collected' },
  { label: 'Collected', value: 'collected' },
  { label: 'Passed to Police', value: 'passed to police' },
  { label: 'Trashed', value: 'trashed' },
];

export const DATES = [
  { label: 'Found date', value: 'foundDate' },
  { label: 'Recovered date', value: 'recoveredDate' },
];
